import type {Path} from '@remix-run/react';
import {useLocation} from '@remix-run/react';
import {useMemo} from 'react';

type To = string | Partial<Path>;

export function useModifiedLinkTo(to: To) {
  const location = useLocation();

  return useMemo<To>(() => {
    const params = new URLSearchParams(location.search);
    const feature = params.get('feature');
    const syncDate = params.get('sync-date');

    if (!feature && !syncDate) {
      return to;
    }

    const modifySearch = (search?: string) => {
      const modifiedParams = new URLSearchParams(search);
      if (feature) {
        modifiedParams.set('feature', feature);
      }
      if (syncDate) {
        modifiedParams.set('sync-date', syncDate);
      }
      return modifiedParams.toString();
    };

    if (typeof to === 'string') {
      const [path, search] = to.split('?', 2);
      return `${path}?${modifySearch(search)}`;
    }

    return {...to, search: `?${modifySearch(to.search)}`};
  }, [location.search, to]);
}
