import type {LinkProps} from '@remix-run/react';
// eslint-disable-next-line no-restricted-imports
import {Link as RemixLink} from '@remix-run/react';
import {forwardRef} from 'react';

import {useModifiedLinkTo} from '../hooks/useModifiedLinkTo';

const _Link = forwardRef<HTMLAnchorElement, LinkProps>(({children, to, ...others}, ref) => {
  const modifiedTo = useModifiedLinkTo(to);

  return (
    <RemixLink ref={ref} to={modifiedTo} {...others}>
      {children}
    </RemixLink>
  );
});

_Link.displayName = 'Link';

export const Link = _Link;
