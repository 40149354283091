import type {NavLinkProps} from '@remix-run/react';
// eslint-disable-next-line no-restricted-imports
import {NavLink as RemixNavLink} from '@remix-run/react';
import {forwardRef} from 'react';

import {useModifiedLinkTo} from '../hooks/useModifiedLinkTo';

const _NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({children, to, ...others}, ref) => {
  const modifiedTo = useModifiedLinkTo(to);

  return (
    <RemixNavLink ref={ref} to={modifiedTo} {...others}>
      {children}
    </RemixNavLink>
  );
});

_NavLink.displayName = 'NavLink';

export const NavLink = _NavLink;
